.header {
    background-color: #00491E;
    color: #fff;
    position: fixed;
    width: 100%;
}

.header__top {
    display: flex;
    align-items: flex-end;
    position: relative;
}

.header__logo {
    object-fit: contain;
    height: auto;
    width: 100%;
    max-width: 180px;
}

.header__topMessage {
    flex: 1;
    background-color: #00491E;
    height: 48.5px;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 1.125rem;
}

.header__topMenuIcon {
    width: 34px;
    height: 34px;
    margin-left: 1rem;
}

.header__menuIcon {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 0.25rem;
    right: 1.25rem;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.header__menuIcon.hide {
    display: none;
}

.header__menuIcon img {
    width: 100%;
    height: auto;
}