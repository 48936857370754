.login {
    height: 100%;
}

.login h1 {
    font-size: 1.125rem;
    text-transform: uppercase;
    text-align: center;
}

.login__form {
    margin-top: 1rem;
}

.login__welcome {
    display: block;
    line-height: 1.6;
    text-align: center;
}

.login__button {
    display: flex;
    justify-content: center;
}