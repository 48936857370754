.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  right: 0;
  position: relative;
  transition: 0.5s;
}

.app.no-transition {
  transition: none !important;
}

.app.hideMenu {
  right: 0;
}

.app.openMenu {
  right: 350px;
}

.page {
  background-color: #F3F3F3;
  padding: 1.25rem 1rem;
  padding-bottom: 7rem;
  box-sizing: content-box;
  flex-grow: 1;
  margin-top: 100px;
}

.home {
  padding: 0 0.375rem;
}

.page--bgRed {
  background-color: #00491E;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--md {
  font-size: 1.125rem;
}

.text--lg {
  font-size: 1.25rem;
}

.text--xl {
  font-size: 1.5rem;
}

.text--2xl {
  font-size: 1.75rem;
}

.text--thin {
  font-weight: 400;
}

.text--gold {
  color: #ffffff;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.text-center {
  text-align: center;
}

.home__title {
  font-weight: 700;
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.leading-none {
  line-height: 1;
}

.font-normal	{
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.card {
  border: 3px solid #00491E;
  border-radius: 10px;
  padding: 1.25rem 0.75rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  max-width: 570px;
  font-size: 0.875rem;
  line-height: 1.6;
}

.card a {
  color: #00491E;
  text-decoration: none;
}

.card--bgRed {
  background-color: #00491E;
  color: #fff;
  border: none;
}

.card--bgGold {
  background-color: #ffffff;
  color: #000;
  border: none;
}

.card--bgGold a {
  color: #000;
}

.card--bgGrey {
  background-color: #F3F3F3;
  border: none;
}

.card__row {
  display: flex;
  align-items: flex-end;
}

.form__instructions {
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
}

.form__title {
  background-color: #1D1D1D;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.form__label {
  display: block;
  margin-top: 0.75rem;
}

.form__input, .form__textarea {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 1.125rem;
  padding: 0.5rem;
  margin-top: 0.25rem;
}

.form__buttons {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
}

.form__button {
  background-color: #00491E;
  border: none;
  color: #fff;
  -webkit-appearance: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
}

.form__button--inline {
  display: flex;
  justify-content: center;
}

.form__button:hover {
  outline: none;
}

.form__select {
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.75rem;
  flex: 1;
  margin-right: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  .app.openMenu {
    right: 60%;
  }
}