.sidemenu {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: #008522; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 52px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    max-width: 60%;
}

.sidemenu.menuOpen {
    width: 350px;
}

.sidemenu.menuClose {
    width: 0;
}

.sidemenu__links {
    margin: 0;
    padding: 0;
}

.sidemenu__link {
    list-style-type: none;
}

.sidemenu__link a {
    display: block;
    padding: 0.9rem;
    text-decoration: none;
    color: #fff;
    border-left: 5px solid transparent;
}

.sidemenu__link a:hover {
    border-color: #fff;
}

.sidemenu__link.active a {
    border-color: #ffffff;
    color: #ffffff;
}

.sidemenu__close {
    position: absolute;
    right: 5%;
    top: 2%;
    background: transparent;
    border: 0;
    color: #fff;
    outline: none;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .sidemenu.menuOpen {
      width: 60%;
    }
  }