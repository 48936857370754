.card__membershipDetails {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0.75rem 0.5rem;
    width: 55%;
    align-self: normal;
}

.card__membershipDetails div {
    word-break: break-all;
    -ms-word-break: break-all;
    line-height: 1.2;
}

.card__membershipImage {
    width: 100%;
    max-width: 130px;
    height: auto;
    object-fit: contain;
    margin-top: 1rem;
}
  
.card__profile {
    padding: 0.5rem;
    width: 45%;
}
  
.card__profilePicture {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: contain;
}
  
.card__qrCode {
    width: 100%;
    max-width: 180px;
    height: auto;
    object-fit: contain;
    margin-top: 1rem;
}