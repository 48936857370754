.page {
    background-color: #F3F3F3;
}

.header__membership {
    font-size: 1rem;
    padding: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-top: 100px;
    color: #fff;
    background-color: #00491E;
  }